.recordBtnBox{
    background: #f2f2f2;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-bottom: 10px;
}

.recordStartBtn{
    width: 120px;
    height: 40px;
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
    color: white;
    background: #13a833e3; 
    border-radius: 30px;
    border: none;
    font-size: 18px;
    font-weight: bold;
}

.recordStartBtn:hover{
    background: #71e089c5;
    transform: scale(1.05);
    transition-duration: 0.3s;
}

.recordStopBtn{
    width: 120px;
    height: 40px;
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
    color: white;
    background: #da2f2fb2; 
    border-radius: 30px;
    border: none;
    font-size: 18px;
    font-weight: bold;
}

.recordStopBtn:hover{
    background: #d67979de;
    transform: scale(1.05);
    transition-duration: 0.3s;
}

.recordPlayBtn{
    width: 40px;
    height: 40px;
    margin-left: 10px;
    margin-bottom: 20px;
    color: white;
    background: #110dfd73; 
    border-radius: 30px;
    border: none;
    font-weight: bold;
}

.recordPlayBtn:hover{
    background: #6562ffa1;
    transform: scale(1.05);
    transition-duration: 0.3s;
}

.recordSendBtn{
    width: 40px;
    height: 40px;
    margin-left: 10px;
    margin-bottom: 20px;
    color: black;
    background: #ffffff; 
    border-radius: 30px;
    border: none;
    font-weight: bold;
}

.recordSendBtn:hover{
    background: #fcfcfc5b;
    transform: scale(1.05);
    transition-duration: 0.3s;
}