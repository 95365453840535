@font-face {
  font-family: "TheJamsilLight";
  src: url("./assets/fonts/TheJamsilLight.ttf");
} 

@font-face {
  font-family: "TheJamsilThin";
  src: url("./assets/fonts/TheJamsilThin.ttf");
} 

@keyframes fadeInUp {
  0% {
      opacity: 0;
      transform: translate3d(0, 30%, 0);
  }
  to {
      opacity: 1;
      transform: translateZ(0);
  }
}

.App {
  text-align: center;
  background-image: linear-gradient(to bottom, #32415f, #788ca3, #9bb7cf); /*bottom right  , #788ca3, #32415f*/
  height: auto;
}

body {
  margin: 0;
}

.container{
  display: flex;
  flex-direction: column; /* 수직 정렬을 위한 flex-direction 추가 */
  position: relative;
  justify-content: center;
  align-items: center;
  height: 100vh;
  animation: fadeInUp 1s;
}

.title{
  font-size: 28px;
  font-weight: 800;
  font-family: "TheJamsilLight";
  color: white;
}

.brand{
  font-size: 36px;
  font-weight: bold;
  color: #3182f6; /*#93A9DA;*/
  margin-bottom: 30px;
}

.content{
  color: white;
  font-family: "TheJamsilLight";
  margin-bottom: 30px;
  font-size: 20px;
  white-space: pre-wrap;
}

.mainBtn{
  background: #313b58;
  width: 300px;
  margin-top: 30px;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  color: white;
  border-radius: 30px;
  border: none;
  font-family: "TheJamsilLight";
}

.mainBtn:hover {
  background: #4d5e92;
  transform: scale(1.1);
  transition-duration: 0.3s;
}

.subBtn{
  background: white;
  width: 300px;
  margin-top: 15px;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  color: #313b58;
  border-radius: 30px;
  border: none;
  font-family: "TheJamsilLight";
}

.subBtn:hover {
  background: white;
  transform: scale(1.1);
  transition-duration: 0.3s;
}

.preForm{
  margin-top: 30px;
  color: white;
}

.preFormContent {
  margin: 0px;
}

.preFormTitle{
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 0px;
  font-family: "TheJamsilLight";
}

.preFormDis{
  font-size: 20px;
  margin-bottom: 0px;
  font-family: "TheJamsilThin";
}

.preFormBox{
  padding: 0px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 768px){
  .logo{
    width: 100%;
  }

  .title{
    font-size: 21px;
  }

  .content{
    font-size: 18px;
  }
}