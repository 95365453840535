body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

.chat-logo{
  cursor: pointer;
}

.chat-container {
  max-width: 70%;
  margin: 0 auto;
  padding: 20px;
/* 
  display: flex;
  flex-direction: column;
  flex: 1; 
  height: 100%; */
}

.chat-title{
  font-size: 22px;
  font-weight: 600;
  font-family: "TheJamsilLight";
  color: white;
  margin: 0px;
}

.chat-box {
  background-color: #f2f2f2;
  padding: 10px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  overflow-y: scroll;
  height: 450px;
  text-align: left;
}

.chat-message {
  background-color: #fff;
  width: 51%;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.chat-message p {
  margin: 0;
  padding: 0;
}

.tutor {
  color: blue;
  float: left;
}

.user {
  float: right;
}

.chat-input {
  display: flex;
  background-color: white;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.chat-input input {
  flex: 1;
  padding: 10px;
  border: none;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-right: 10px;
}

.chat-input button {
  background-color: #13a833;
  color: #fff;
  border: none;
  padding: 10px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.chat-input button:hover {
  background-color: #71e089c5;
  /*transform: scale(1.03);
  transition-duration: 0.3s;*/
}

.convFinishBtn{
  width: 120px;
  height: 50px;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 20px;
  color: white;
  background: #222d4f; 
  border-radius: 30px;
  border: none;
  font-weight: bold;
  white-space: pre-line;
}

.convFinishBtn:hover{
  background: #303953;
  /*transform: scale(1.05);
  transition-duration: 0.3s;*/
}

.Feedback {
  background-color: #f6f6f6;
  text-align: left;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  padding: 20px;
  margin: 20px;
}

.Feedback h3 {
  color: #333;
  font-weight: bold;
  font-size: 1.2rem;
  margin-top: 0;
}

.Feedback h4 {
  color: #333;
  font-weight: bold;
  font-size: 1.2rem;
  margin-top: 0;
}

.Feedback hr {
  border: none;
  border-top: 1px solid #000000;
  margin: 20px 0;
}

.Feedback ul {
  list-style-type: none;
  padding-left: 0;
}

.Feedback ul li {
  margin-bottom: 10px;
}

.Feedback li:before {
  content: "✔ ";
}

.Feedback p {
  margin: 20px 0;
}

.userSituBox{
  background-color: rgb(247, 249, 250);
  border-radius: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin: 10px 0;
}

.userSituBox p{
  margin: 0px;
  font-family: "TheJamsilLight";
  font-weight: 800;
  font-size: 16px;
}

.userSituBox p userSituContent{
  font-size: 14px;
}

@media screen and (max-width: 768px){
  .chat-container {
    max-width: 90%;
  }

  .chat-logo{
    width: 100%;
  }

  .chat-title{
    font-size: 16px;
  }
}