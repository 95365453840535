.ChatSituation {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
    animation: fadeInUp 1s;  
}

.userForm {
    margin-left: auto;
    margin-right: auto;
    background-color: transparent;
    color: white;
    border: none;
}

.formTitle{
    font-size: 28px;
    margin-bottom: 20px;
    font-family: "TheJamsilLight";
}

.formDesc{
    font-size: 18px;
    margin-bottom: 20px;
    font-family: "TheJamsilLight";
}

.situationInput{
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
}

Stack{
    margin-bottom: 10px;
}

.example{
    width: 700px;
    margin-left: auto;
    margin-right: auto;
}

.exampleBox{
    width: 200px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    color: white;
    background-image: linear-gradient(to bottom right, #9bb7cf, #3182f6, #9bb7cf); /*bottom right  , #788ca3, #32415f 3182f6*/
    /*background: #3182f6;*/
    border-radius: 30px;
    border: none;
    font-size: 16px;
    font-family: "TheJamsilLight";
} 
  
.exampleBox:hover{
    background: #4d94f7;
    transform: scale(1.1);
    transition-duration: 0.3s;
}

.mainBtn{
    margin-top: 10px;
    height: 50px;
    border-radius: 30px;
    font-weight: 300;
}

@media screen and (max-width: 768px){
    .example{
        width: 330px;
        margin-left: auto;
        margin-right: auto;
        font-size: smaller;
    }

    .exampleBox{
        font-size: 14px;
    }
}