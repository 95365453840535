.ChatStart {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
    animation: fadeInUp 1s;  
}

.userForm {
    margin-left: auto;
    margin-right: auto;
    background-color: transparent;
    color: white;
    border: none;
}

.formTitle{
    font-size: 28px;
    margin-bottom: 20px;
}

.formDesc{
    margin-bottom: 30px;
}

.situationInput{
    width: 70%;
    height: 100px;
    margin-left: 15%;
    margin-right: 15%;
    border-radius: 15px;
}

.click{
    height: 50px;
}